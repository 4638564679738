
import { axiosInstance } from "@/services/api";
import Vue from "vue";
export default Vue.extend({
  props: {
    value: Boolean,
  },
  data() {
    return {
      site: null,
      copied: null,
    };
  },
  async mounted() {
    const { siteId } = this.$route.params;
    const { data } = await axiosInstance.get("/users/guests", {
      params: { siteId },
    });

    if (data.site) {
      this.site = data.site;
    }
  },
  computed: {
    guests() {
      const { site } = this;

      if (site && site.guests && site.guests.length > 0) {
        const siteGuests = [...site.guests];
        return siteGuests.reverse();
      }
      return [];
    },
  },
  methods: {
    onCopyGuest(guest: string) {
      const { formatGuest } = this;
      const guestUrl = formatGuest(guest);

      navigator.clipboard.writeText(guestUrl);
      this.copied = guest;

      setTimeout(() => {
        this.copied = null;
      }, 2000);
    },
    async onAddGuest() {
      const { siteId } = this.$route.params;
      const { data } = await axiosInstance.post("/users/add-guest", {
        siteId,
      });

      if (data?.guestId) {
        this.site = {
          ...this.site,
          guests: [...this.site.guests, data.guestId],
        };
      }
    },
    async onDeleteGuest(guestId: string) {
      const { siteId } = this.$route.params;
      const { data } = await axiosInstance.post("/users/remove-guest", {
        siteId,
        guestId,
      });

      if (data?.success) {
        this.site = {
          ...this.site,
          guests: [...this.site.guests].filter((g) => g != guestId),
        };
      }
    },
    formatGuest(hash: string) {
      const { siteId } = this.$route.params;

      return "https://sparky.run/guest/" + siteId + "/" + hash;
    },
  },
});

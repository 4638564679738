
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      reasons: [
        {
          icon: "mdi-check",
          title: "Setup in 1 minute",
          description:
            "Quick and easy to set up with the most popular tools such as WordPress, Shopify, and Wix. Sparky is also very easy to set up for your React, Vuejs, or Angular web applications and also supports static websites.",
        },
        {
          icon: "mdi-bullseye-arrow",
          title: "Marketing campaigns",
          description:
            "Use the UTM tag during your marketing campaigns in you paid ads, social media posts, paid newsletter campaigns.. and track the visits, conversions and actions made on your website by the new visitors. You can even setup goals and watch its progress.",
        },
        {
          icon: "mdi-alarm-light-outline",
          title: "Event-Driven Analytics",
          description:
            "Sparky is an event-driven analytic tool, allowing you to send a multitude of data types to be retrieved, presented and analyzed in different ways and shapes. Consider it as the “notion” of web analytics.",
        },
        {
          icon: "mdi-lock",
          title: "Your App, your data",
          description:
            "Sparky isn't involved in adtech. The reason why the data from your site will never be shared or sold to third parties. Also It will never be monetized or exploited by Sparky. When we say “You are in complete control of your data”, we mean it.",
        },
      ],
    };
  },
});

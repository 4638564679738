
import Features from "@/components/Home/Features.vue";
import Hero from "@/components/Home/Hero.vue";
import Why from "@/components/Home/Why.vue";
import Pricing from "@/components/Home/Pricing.vue";
import Vue from "vue";

export default Vue.extend({
  name: "HomeView",
  components: { Hero, Features, Why, Pricing },
});

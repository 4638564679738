import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.site)?_c(VDialog,{attrs:{"value":_vm.value,"width":"800","scrollable":""},on:{"click:outside":function($event){return _vm.$emit('close')}}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Add / Remove links for your Guests ")]),_c(VCardText,{staticClass:"py-4"},[_c(VBtn,{staticClass:"mb-4 fullwidth",attrs:{"color":"primary"},on:{"click":_vm.onAddGuest}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Add guest ")],1),_vm._l((_vm.guests),function(guest){return _c('div',{key:`guest-${guest}`,staticClass:"d-flex align-center my-2"},[_c(VTextField,{attrs:{"readonly":"","value":_vm.formatGuest(guest),"outlined":"","dense":"","hide-details":""}}),_c(VBtn,{staticClass:"ml-2",attrs:{"color":_vm.copied === guest ? 'success' : null},on:{"click":function($event){return _vm.onCopyGuest(guest)}}},[(_vm.copied !== guest)?_c('span',[_vm._v("Copy")]):_c('span',[_vm._v("Copied")])]),_c(VBtn,{staticClass:"ml-2",on:{"click":function($event){return _vm.onDeleteGuest(guest)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)],1)}),(_vm.guests.length === 0)?_c('div',[_vm._v(" Add a new guest to share this dashboard with someone ")]):_vm._e()],2),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Close ")])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
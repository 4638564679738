import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/beta",
    name: "beta",
    component: () => import("@/views/BetaView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/RegisterView.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/ForgotPasswordView.vue"),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("@/views/ResetPasswordView.vue"),
  },
  {
    path: "/first-connexion",
    name: "first-connexion",
    component: () => import("@/views/FirstConnexionView.vue"),
  },
  {
    path: "/add-site",
    name: "add-site",
    component: () => import("@/views/AddSiteView.vue"),
  },
  {
    path: "/dashboard/:siteId",
    name: "dashboard",
    component: () => import("@/views/DashboardView.vue"),
  },
  {
    path: "/guest/:siteId/:guestId",
    name: "guest-dashboard",
    component: () => import("@/views/GuestView.vue"),
  },
  {
    path: "/verify-email",
    name: "verify-email",
    component: () => import("@/views/VerifyEmailView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;


import { axiosInstance } from "@/services/api";
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      items: [],
      site: null,
    };
  },
  async mounted() {
    const { siteId } = this.$route.params;
    const { data } = await axiosInstance.get<
      {
        id: string;
        name: string;
      }[]
    >("/users/me");

    this.items = data.user.sites;
    this.site = data.user.sites.find(({ _id }) => _id === siteId);
  },
  methods: {
    onChange(siteId: string) {
      this.site = this.items.find(({ _id }) => _id === siteId);
      window.location = `/dashboard/${siteId}`;
    },
  },
});

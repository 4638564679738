import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "./variables.scss";

Vue.use(Vuetify);

const commonTheme = {
  primary: "#3244c6",
  secondary: "#2fa8f2",
};

export default new Vuetify({
  theme: {
    themes: {
      dark: commonTheme,
      light: commonTheme,
    },
  },
});

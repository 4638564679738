import axios from "axios";

const cookieValue = localStorage.getItem("auth-token");

export const axiosInstance = axios.create({
  headers: {
    Authorization: `Bearer ${cookieValue}`,
  },
  baseURL: process.env.VUE_APP_API_URL,
});

import axios from "axios";

export function isEmailValid(email: string): boolean {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,7})+$/.test(email)) {
    return true;
  }
  return false;
}

export function isPasswordValid(password: string): boolean {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/.test(password);
}

export async function registerEmail(email: string): Promise<void> {
  return axios.post(`${process.env.VUE_APP_WORKER_URL}/register`, {
    email,
  });
}

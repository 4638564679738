
import Vue from "vue";
import { isEmailValid, registerEmail } from "@/services/register";

export default Vue.extend({
  name: "HomeHero",
  data() {
    return {
      email: "",
      emailError: "",
      loading: false,
    };
  },
  methods: {
    async onSubmit() {
      const { email } = this;

      if (email && isEmailValid(email)) {
        this.loading = true;

        await registerEmail(email);

        this.emailError = "";
        this.email = "";
        this.loading = false;
        this.$router.push("/beta");
      } else {
        this.emailError = "Should be a valid email";
      }
    },
  },
});

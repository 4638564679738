
import { axiosInstance } from "@/services/api";
import Vue from "vue";
import AvailableSites from "./AvailableSites.vue";
import Pricing from "./Home/Pricing.vue";
import ManageGuests from "./ManageGuests.vue";

export default Vue.extend({
  components: {
    AvailableSites,
    ManageGuests,
    Pricing,
  },
  data() {
    return {
      isManageGuestsDialogOpen: false,
      isUpgradeDialogOpen: false,
      user: null,
    };
  },
  mounted() {
    axiosInstance.get("/users/me").then(({ data }) => {
      if (data.user) {
        this.user = data.user;
      }
    });
  },
  methods: {
    onOpenManageGests() {
      this.isManageGuestsDialogOpen = true;
    },
    logout() {
      localStorage.removeItem("auth-token");
      window.location = "/";
    },
  },
});
